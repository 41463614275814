var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _vm._l(_vm.keys, function (key, idx) {
        return _c(
          "div",
          { key: "" + key + idx },
          [
            _c("mew-input", {
              staticClass: "mb-2",
              attrs: {
                id: idx,
                "error-messages": _vm.errors[key],
                label: key,
                placeholder: key,
              },
              on: { input: _vm.validateRecord },
              model: {
                value: _vm.setRecords[key],
                callback: function ($$v) {
                  _vm.$set(_vm.setRecords, key, $$v)
                },
                expression: "setRecords[key]",
              },
            }),
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "d-flex align-center justify-center mt-3" },
        [
          _c("mew-button", {
            attrs: {
              title: _vm.$t("common.save"),
              "btn-size": "xlarge",
              disabled: !_vm.isValid,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.callSetRecords.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }